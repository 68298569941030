import React, { useState } from "react";
import PurchaseModal from "./PurchaseModal";

export const Products = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleBuyNow = (productType) => {
    setSelectedProduct(productType);
    setModalOpen(true);
  };

  return (
    <div id="products" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Choose Your Plan</h2>
          <p>Select the weight loss plan that best suits your needs</p>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="product-item">
              <h3>Custom Weight Loss Plan</h3>
              <div className="price">R1299</div>
              <ul className="features-list">
                <li>Personalized meal planning</li>
                <li>Customized to your preferences</li>
                <li>4-week detailed plan</li>
                <li>Shopping lists included</li>
                <li>Emailed within 3 working days from payment</li>
              </ul>
              <button
                className="btn btn-custom btn-lg"
                onClick={() => handleBuyNow("custom")}
              >
                Buy Now
              </button>
            </div>
          </div>
          <div className="col-md-6">
            <div className="product-item">
              <h3>Budget Weight Loss Plan</h3>
              <div className="price">R799</div>
              <ul className="features-list">
                <li>Ready-to-use meal plan</li>
                <li>Proven results</li>
                <li>Basic shopping lists</li>
                <li>4-week standard plan</li>
                <li>Emailed immediately on payment</li>
              </ul>
              <button
                className="btn btn-custom btn-lg"
                onClick={() => handleBuyNow("premade")}
              >
                Buy Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <PurchaseModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        productType={selectedProduct}
      />
    </div>
  );
};
