import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51PyBNKCvmpgkGAEsEoWYDoFQr4QOjZDA50H5AaHfi9kNHXeIBlyt7rrTH4oLwVsf3iHW55vR5P0onKmyRdUHihqg00tzc4Zmnl"
);

const PaymentPage = () => {
  const { productId } = useParams();
  const [loading, setLoading] = useState(false);

  const getProductDetails = (id) => {
    const products = {
      custom: {
        name: "Custom Weight Loss Plan",
        amount: 84900, // R849.00 in cents
        description: "Personalized 4-week weight loss plan",
      },
      premade: {
        name: "Premade Weight Loss Plan",
        amount: 44900, // R449.00 in cents
        description: "Standard 4-week weight loss plan",
      },
    };
    return products[id] || products.custom;
  };

  const product = getProductDetails(productId);

  const handleCheckout = async () => {
    try {
      setLoading(true);

      // Update the fetch URL to the correct endpoint
      const response = await fetch("/api/create-checkout-session", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productName: product.name,
          amount: product.amount,
        }),
      });

      const { sessionId } = await response.json();

      // Redirect to Stripe Checkout
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId,
      });

      if (error) {
        console.error("Error:", error);
      }
    } catch (err) {
      console.error("Error:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div id="payment" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Complete Your Purchase</h2>
          <p>
            {product.name} - R{product.amount / 100}
          </p>
        </div>
        <button
          onClick={handleCheckout}
          className="btn btn-custom btn-lg"
          disabled={loading}
        >
          {loading ? "Loading..." : "Proceed to Payment"}
        </button>
      </div>
    </div>
  );
};

export default PaymentPage;
