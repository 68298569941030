import React, { useState } from "react";
import emailjs from "emailjs-com";

const PurchaseModal = ({ isOpen, onClose, productType }) => {
  const [email, setEmail] = useState("");
  const [sending, setSending] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);
    setError("");

    // Create form data object to match contact form pattern
    const templateParams = {
      email: email,
      product_type: productType,
      message:
        productType === "custom"
          ? "Thank you for your interest! A questionnaire and invoice will be sent to you shortly. Please note that it will take 3 working days from the payment date to receive your custom plan."
          : "Thank you for your purchase! Your premade plan will be emailed to you as soon as payment is confirmed.",
    };

    emailjs
      .sendForm(
        "service_3y4xvfc",
        "template_rqco6tk",
        e.target,
        "7ExTPBLy5SFzpGcgL"
      )
      .then(
        (result) => {
          console.log(result.text);
          setSuccess(true);

          // Send notification to admin
          emailjs.sendForm(
            "service_3y4xvfc",
            "template_rqco6tk",
            {
              email: "howzit@slimshape.co.za",
              product_type: productType,
              customer_email: email,
              message: `New ${productType} plan purchase request from ${email}`,
            },
            "7ExTPBLy5SFzpGcgL"
          );
        },
        (error) => {
          console.log(error.text);
          setError(
            "Failed to send email. Please try again or contact us directly."
          );
        }
      )
      .finally(() => {
        setSending(false);
      });
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close" onClick={onClose}>
          ×
        </button>
        {!success ? (
          <>
            <h3>Complete Your Purchase</h3>
            <p>
              {productType === "custom"
                ? "Please provide your email to receive the questionnaire and invoice."
                : "Please provide your email to receive the invoice and plan after payment."}
            </p>
            <form name="purchaseForm" validate onSubmit={handleSubmit}>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
                required
                className="form-control"
              />
              {error && <div className="error-message">{error}</div>}
              <button
                type="submit"
                className="btn btn-custom btn-lg"
                disabled={sending}
              >
                {sending ? "Sending..." : "Submit"}
              </button>
            </form>
          </>
        ) : (
          <div className="success-message">
            <h3>Thank You!</h3>
            <p>
              {productType === "custom"
                ? "You will receive the questionnaire and invoice shortly."
                : "You will receive the invoice shortly. The plan will be sent after payment confirmation."}
            </p>
            <button className="btn btn-custom btn-lg" onClick={onClose}>
              Close
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseModal;
